import { lazy } from "react";
import { Navigate, createBrowserRouter, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import PageRouteList from "./RouteList";
import { globalStorage } from "./helpers";

const MainLayout = lazy(() => import("./layouts/mainLayout"));
const LoginPage = lazy(() => import("./pages/login"));
const ChooseUnitPage = lazy(() => import("./pages/unit"));

const GetAccessToken = () => Cookies.get("fin_access_token");
const GetRefreshToken = () => Cookies.get("fin_refresh_token");
const IsAuthenticated = () => !!GetAccessToken();

export const RequireAuth = ({ children }) => {
  const { pathname } = useLocation();
  let auth = IsAuthenticated();
  if (!auth) {
    Cookies.remove("fin_access_token");
    Cookies.remove("fin_unit_data");
    globalStorage.removeItem("fin_user_data");
    globalStorage.removeItem("fin_menu_data");
    globalStorage.removeItem("fin_units");
    Cookies.remove("fin_refresh_token");
    return <Navigate to="/login" state={{ prev: pathname }} />;
  }

  if (GetRefreshToken()) {
    try {
      const tokens = Cookies.get("fin_access_token");
      const expires = (tokens.expires_in || 30 * 60) * 10000000000000;
      const inOneHour = new Date(new Date().getTime() + expires);
      Cookies.set("fin_access_token", tokens, { expires: inOneHour });
      Cookies.set("fin_refresh_token", "refresh_token");

      return children;
    } catch (error) {
      Cookies.remove("fin_access_token");
      Cookies.remove("fin_unit_data");
      globalStorage.removeItem("fin_user_data");
      globalStorage.removeItem("fin_menu_data");
      globalStorage.removeItem("fin_units");
      Cookies.remove("fin_refresh_token");
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  return children;
};

export default function PageRoutes() {
  const Router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/pilih-unit",
      label: "Pilih Unit",
      element: (
        <RequireAuth>
          <ChooseUnitPage />
        </RequireAuth>
      ),
    },
    {
      path: "/",
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: PageRouteList().RouteList,
    },
  ]);
  return { Router };
}
